$dark: #2a394d !default;
$primary: #0063e5 !default;
$secondary: #3a495d !default;

// TODO: sort and remove extra rules from this file
// use different files for this purposes

// Import styles with default layout.
@import "~@coreui/coreui-pro/scss/coreui.scss";
// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// need to get rid of it - update all icons to the font-awesome icons
@import "~@neos21/bootstrap3-glyphicons/dist/css/bootstrap3-glyphicons.css";

@import 'react-bootstrap-table';
@import "autosuggest";
@import "~react-datetime/css/react-datetime.css";

// Import Font Awesome Pro Icons
@import '~@fortawesome/fontawesome-pro/css/all.min.css';

// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";


 html body {
    background:#fff;
}
@media (min-width:992px) {
    html body {
        background:var(--light);
    }
}
footer.footer-row {padding:1em;text-align:center;background:$dark;margin-top:1em;color:#fff;font-size:2em;}
.menu-row {background:$dark;}
.menu-row nav {padding-left:0;padding-right:0;}
.top-header-row {text-align:right;background:$secondary;}
.top-header-row .dropdown-menu {padding-bottom:0;border:none;box-shadow:0 0 0.15em rgba(0,0,0,0.35);border-radius:0;}
.top-header-row button {border-radius: 0;}
.top-header-row .dropdown-item, .top-header-row .dropdown-header {padding:0.25rem 1rem;}
.top-header-row .dropdown-menu .logoutButton {background:var(--danger) !important;color:rgba(255,255,255,0.85) !important;font-size:0.9em;padding:0.45em 1em;}
.permissions-list {
    list-style-type: none;
    margin:0;
    padding: 0.35em 1em;
    font-size:0.85em;
}
.menu-row {margin-bottom:20px;}

.w-10 {
    width: 10%;
}

th, td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

table.table td, table.table th {
    vertical-align: middle;
}

.table-sm td, .table-sm th {
    padding-right: 1rem;
}

//button.editor-control {
//    border: 1px solid rgba(0, 0, 0, 0.125);
//}

div[class*="ButtonGroup__root_"] {
    button {
        border: 1px solid rgba(0, 0, 0, 0.125);

        span {
            opacity: 0.7;
        }
    }
}

span[class*="Dropdown__value_"] {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.tab-content-border-radius {
    border-bottom: none;
    border-radius: 0 3px 3px 3px;
}

th.actions {
    width: 170px;
}

.with-radius {
    @media screen and (max-width: 768px) {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
}

td.actions .action-button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
    line-height: inherit;
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: white;
    transition: all 0.1s ease-in;
    border-radius: 6px !important;
    text-align: center;

    &:hover {
        transform: scale(1.1);
        background: white;
    }

   i,
   svg {
       color: #6c757d;
   }
}

td.actions a {
 padding-top: 4px !important;
}

.Flash {
    position: fixed;
    top: 4em;
    right: 1em;
    z-index: 9999;
}
.Flash .alert {margin-bottom:0.25rem;}

.Form {
    width: 90%;
    margin: auto;
}

.Filter {
    border: solid 1px #ccc;
    padding: 20px;
    width: 70%;
}

.Filter label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    color: #bbb;
    margin-top: -10px;
    font-weight: bold;
}

.array-item > .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.array-item > .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.array-item > .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.array-item {
    clear: both;
    display: flex;
    width: 100%;
    border: solid 1px #ccc;
    padding: 10px;
    margin-bottom: 20px;
}

.scroll-x {
  overflow:hidden;
  overflow-x: scroll;
  display: block;
  width:100%;
  table {min-width:980px;}
}

.rdtPicker .rdtDays table td, .rdtPicker .rdtDays table th {
    padding: 0.25rem;
}

.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  
    .sk-circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    
    .sk-circle:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #333;
        border-radius: 100%;
        animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
    .sk-circle2 {
        transform: rotate(30deg);
    }

    .sk-circle3 {
        transform: rotate(60deg);
    }

    .sk-circle4 {
        transform: rotate(90deg);
    }

    .sk-circle5 {
        transform: rotate(120deg);
    }

    .sk-circle6 {
        transform: rotate(150deg);
    }

    .sk-circle7 {
        transform: rotate(180deg);
    }

    .sk-circle8 {
        transform: rotate(210deg);
    }

    .sk-circle9 {
        transform: rotate(240deg);
    }

    .sk-circle10 {
        transform: rotate(270deg);
    }

    .sk-circle11 {
        transform: rotate(300deg); 
    }

    .sk-circle12 {
        transform: rotate(330deg); 
    }

    .sk-circle2:before {
        animation-delay: -1.1s; 
    }

    .sk-circle3:before {
        animation-delay: -1s; 
    }

    .sk-circle4:before {
        animation-delay: -0.9s; 
    }

    .sk-circle5:before {
        animation-delay: -0.8s; 
    }

    .sk-circle6:before {
        animation-delay: -0.7s; 
    }

    .sk-circle7:before {
        animation-delay: -0.6s; 
    }

    .sk-circle8:before {
        animation-delay: -0.5s; 
    }

    .sk-circle9:before {
        animation-delay: -0.4s;
    }

    .sk-circle10:before {
        animation-delay: -0.3s;
    }

    .sk-circle11:before {
        animation-delay: -0.2s;
    }

    .sk-circle12:before {
        animation-delay: -0.1s;
    }
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
}

.tabSeparator {
    margin: 0 -1rem;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 576px) {
        flex-direction: row;
        justify-content: space-between;
    }

    .footerPowered {
        height: 18px;
        @media (max-width:767px) {
            display: none;
        }
    }
}

.colorBox {
    height: 30px;
    width: 30px;
    border: 1px solid #c8ced3;
}

.distributionModal {
    overflow: auto;
    max-height: 70vh;
}

.popoverCustom {
    min-width: 10rem;
}

.sidebar-minimized i.nav-icon.empty {
    width: 30px !important;
}

.sidebar-minimized .nav-icon.empty:before {
    content: '';
    display: inline-block;
    width: 0;
}

.overflowVisible {
    overflow: visible;
}

.autoSuggest {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 11;
}

.mediaDescription {
    & > *:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > *:not(:first-child) {
        display: none;
    }
}

.valuePadding {
    padding-top: 0.7rem;
}

.dateInput {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #66a8ff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 99, 229, 0.25);
    }
}
.mt-m1 {margin-top:-0.5em !important;}
.adminWrapper, .customerWrapper {
    padding:1em;
    background:#f8f8f8;
    border:1px solid rgba(0,0,0,0.065)
}
.key-val-val .btn-link, .btn-flush-left {padding-left:0 !important;}
.pdf-link, .pdf-link:hover {color:red;display: block;text-align: start;}
.pdf-link .fa-file-pdf {margin-right:0.35em;}
.pdf-link .fa-file-pdf:hover {text-decoration: none;}
.link-button {cursor:pointer}
.link-button:hover {text-decoration: underline;cursor:pointer}
.previewThumbnail {
    width: 200px;
    margin: auto;

    img {
        max-width: 100%;
        box-shadow: 0 0 1px rgba(0,0,0,.15);
    }

    figcaption {
        text-align: center;
        margin-top: 0.25em;
    }
}
