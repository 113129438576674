// Here you can add other styles

.content-nav {
    background: #e9ecef;
}
.odd-color {background:rgba(0,255,255,0.2);}
.btn-menu {
    .btn-group, & .btn {
        display:block;
        width:100%;
    }
    .btn {
        text-align:left !important;
    }
    .dropdown-menu {
        min-width:100% !important;
    }
}
.content-wrapper {
    .content-column-main {
        .card {
            .badge {
                vertical-align: text-top;
                margin: 0 0.35em;
            }
        }
    }
    @media (max-width:991px) {
        margin-left:-30px;
        margin-right:-30px;
    }
    @media (min-width:992px) {
        background:#fff;
        border:1px solid rgba(0,0,0,0.1);
    }
    .content-row {
        @media (min-width: 768px) {
            flex-direction: row-reverse;
        }
    }
    .content-column {
        width: 100%;
        @media (min-width: 992px) {
            max-width: calc(100% - 250px);
        }
    }
    .content-column-main {
        width:100%;
    }
    .content-nav-right +.content-column-main {
        @media (min-width: 1200px) {
            max-width: calc(100% - 250px);
        }
    }
    & .content-nav {
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        &-left {min-width:250px;max-width:250px;}
        &-right {
            min-width:100%;
            background:#f8f8f8;
            @media (min-width:1200px) {
                max-width:250px;
                min-width:250px;
            }
        }
        .nav-link {
            border-radius: 0;
            padding:0.25rem 1rem;
            &.active {}
        }
    }
}
.rh-100 {
    height:100%;
}

@import "_key-val.scss";
@import "_conditions-builder.scss";

.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

img {max-width:100%;}
img.provider-logo {
    min-width:100px;
    max-width: 100px;
}
.smallHR {margin-left:0;min-width:100px;max-width: 10vw;}

