
.conditions-builder {

    &.busy {
        user-select: none;
        pointer-events: none;
        opacity: 0.4;
    }

    .panel-group {
        margin-bottom: 2rem;
    }

    .panel-group > .title {
        font-weight: bold;
        text-align: center;
    }

    .panel-group > .panels {
        border: solid 1px #E4E4E5;
        border-radius: 1em;
        display: flex;

        .panel {
            flex: 1;

            max-width: 20rem;

            border-right: solid 3px #E4E4E5;

            > button {
                float: right;
                background: none;
                border: none;
            }

            .title {
                font-weight: bolder;
                font-size: 0.8rem;
                text-transform: uppercase;
                color: #000;

                margin: 0.4rem 0.6rem 0.6rem 0.6rem;
            }

            .content {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0.8rem 2rem 0.8rem;

                    li {
                        > button {
                            background: none;
                            border: none;
                            padding: 0.25rem 1rem;
                            color: #0063e5;
                            width: 100%;
                        }

                        &.selected > button {
                            background: #0063e5;
                            color: #fff;
                        }

                        &.disabled > button {
                            color: #E4E4E5;
                        }
                    }
                }
            }
        }
    }

    .add-panel {
        flex: 1;
        margin: 2rem;

        .icon {
            float: left;
            font-size: 3em;
            color: #0063e5;
            user-select: none;
        }

        ul {
            list-style: none;

            li > button {
                border: none;
                background: none;
                color: #0063e5;
            }
        }
    }
}
