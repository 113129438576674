
.key-val {
    margin-bottom: 0.65rem;

    .key-val-key {
        font-weight: bolder;
        font-size: 0.8rem;
        text-transform: uppercase;
        color: #000;

        button {
            padding: 0;
            margin-left: 1rem;
        }
    }

    &.key-val-inline {
        display: inline-block;
        margin-right: 0.5rem;

        .key-val-key, .key-val-val {
            display: inline-block;
            margin-right: 0.5rem;
        }

        .key-val-key::after {
            content: ':';
        }
    }

    .key-val-last-change {
        color: #bbb;
        font-size: 0.8em;
        margin-left: 2em;
    }
}

.key-val-group {
    border: solid 1px rgba(0,0,0,0.065);
    background: #fff;
    padding: 0.1rem 0.4rem 0.5rem 1rem;
    margin-top: 0.2rem;
    max-width: 30rem;

    .key-val {
        margin-bottom: 0;
        clear: left;

        .key-val-key {
            min-width: 7rem;
            float: left;
            padding-top: 0.2rem;
            position: relative;
            margin-right: 1em;
            text-align: right;

            button {
                position: absolute;
                right: 0.5rem;
            }
        }
    }
}
