body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media (min-width: 850px) {
    .modal-dialog {
        max-width: 800px !important;
    }
}

img {max-width:100%;}

.cursor-pointer {
  cursor: pointer;
}
