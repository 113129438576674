@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

.react-bootstrap-table {
  display: block;
  width: 100%;
  overflow-x: auto;

  & table {
      table-layout: auto;
  }
}